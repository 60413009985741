<template>
    <el-dialog
        :title="settleType == 1 ? '线下打款' : '线上打款'"
        :visible="visible"
        @close="cancel"
        width="450px"
    >
        <div class="payment-content">
            <p>
                <span class="label">结算门店：</span>
                <span class="color-red">{{ detail.store ? detail.store.storeName : '-' }}</span>
            </p>
            <p v-if="settleType == 2">
                <span class="label">收款账户姓名：</span>
                <span class="color-red">{{ detail.store ? detail.store.payeeIdName : '-' }}</span>
            </p>
            <p v-if="settleType == 2">
                <span class="label">收款支付宝账号：</span>
                <span class="color-red">{{
                    detail.store ? detail.store.payeeAlipayAccount : '-'
                }}</span>
            </p>
            <p>
                <span class="label">门店结算金额：</span>
                <span class="color-red f18"
                    >{{ detail.settleAmount || '-' }}<span class="f12">元</span></span
                >
            </p>
            <br />
            <p>
                <span class="label">订单号：</span>
                <span>{{ orderNo || '-' }}</span>
            </p>
            <p>
                <span class="label">商品信息：</span>
                <span>{{ detail.goodsTitle || '-' }}</span>
            </p>
            <p>
                <span class="label">商品进货价：</span>
                <span>￥{{ detail.costAmount }}</span>
            </p>
            <p>
                <span class="label">客户首付款：</span>
                <span
                    >￥{{ detail.prepayAmount }}（{{
                        detail.prepayPayeeType == 1 ? '平台收取' : '门店收取'
                    }}）
                </span>
            </p>
            <p>
                <span class="label">监管锁费用：</span>
                <span
                    >￥{{ detail.lockAmount }} （{{
                        detail.lockPayeeType == 1 ? '平台收取' : '门店收取'
                    }}）</span
                >
            </p>
            <p>
                <span class="label">订单押金：</span>
                <span
                    >￥{{ detail.depositAmount }}（{{
                        detail.depositPayeeType == 1 ? '平台收取' : '门店收取'
                    }}）</span
                >
            </p>
        </div>
        <div slot="footer" class="footer">
            <div class="footer-tips">{{ settleType === 1 ? '请仔细核对!' : '' }}</div>
            <div>
                <el-button @click="cancel" size="mini">取 消</el-button>
                <el-button size="mini" type="primary" v-if="settleType === 1" @click="offlinePay"
                    >已完成打款</el-button
                >
                <el-button
                    size="mini"
                    type="primary"
                    v-if="settleType === 2"
                    @click="onlinePayRetry"
                    >打款重试</el-button
                >
            </div>
        </div>
    </el-dialog>
</template>

<script>
import { getSettleInfo, offlinePay, alipayRetry } from './api';
export default {
    props: {
        visible: { type: Boolean, default: false },
        settleType: { type: Number, default: 1 },
        orderNo: { type: String, default: '' },
    },
    data() {
        return {
            detail: {
                costAmount: '',
                depositAmount: '',
                depositPayeeType: 0,
                goodsTitle: '',
                lockAmount: '',
                lockPayeeType: 1,
                prepayAmount: '',
                prepayPayeeType: 1,
                settleAmount: '',
                store: null,
            },
        };
    },
    watch: {
        visible(visible) {
            if (!visible) {
                return;
            }

            this.init();
        },
    },
    methods: {
        cancel() {
            this.$emit('update:visible', false);
        },
        async onlinePayRetry() {
            await alipayRetry({
                orderNo: this.orderNo,
            });

            this.$message.success('重试成功');

            this.$emit('ok');
        },
        async offlinePay() {
            await offlinePay({
                orderNo: this.orderNo,
            });

            this.$message.success('打款成功');

            this.$emit('ok');
        },
        async init() {
            const response = await getSettleInfo({
                orderNo: this.orderNo,
                settleType: this.settleType,
            });

            this.detail = response;
        },
    },
};
</script>

<style lang="scss" scoped>
.payment-content {
    display: flex;
    justify-content: center;
    flex-direction: column;
    > p {
        line-height: 30px;
    }
    .label {
        font-weight: 700;
        width: 120px;
        display: inline-block;
        text-align: right;
    }
    .color-red {
        color: rgb(151, 3, 3);
    }
}

.footer {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    position: relative;

    .footer-tips {
        color: red;
        position: absolute;
        top: -23px;
    }
}
</style>
